import React from "react";
import Layout from "../Components/Layout";
import tw from "twin.macro";
import "styled-components/macro";

export const Imprint = () => {
  return (
    <Layout>
      <div tw="container mx-auto py-36">
        <div>
          <h1>Impressum </h1>
          <h2>Betreiber dieser Internetseite </h2>
          <p>
            Hotel- und Gaststättenverband DEHOGA Baden-Württemberg e.V.
            Augustenstr. 6 70178 Stuttgart Telefon:{" "}
            <a href="tel:0711619880">0711 / 61988-0</a>
            Telefax: 0711 / 61988-46 E-Mail: hgf (at) dehogabw.de Internet:{" "}
            <a href="http://www.dehogabw.de/">www.dehogabw.de</a>
          </p>
          <p></p>
          <p>
            <strong>Vertretungsberechtigter Vorstand:</strong>
            Fritz Engelhardt, Vorsitzender (Anschrift wie oben)
          </p>
          <p>
            Registergericht: Amtsgericht Stuttgart Registernummer: VR 937
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a UstG: DE 147807086
          </p>
          <p>
            Inhaltlich Verantwortlicher gemäß § 18 II MStV: Jürgen Kirchherr,
            Hauptgeschäftsführer (Anschrift wie oben)
          </p>
          <p>
            Berufshaftpflichtversicherung für die Rechtsberatung gemäß § 7 RDG:
            Haftpflichtkasse Darmstadt, Arheiliger Weg 5, 64380 Roßdorf
          </p>
          <p>Rechtliche Hinweise</p>
          <h2>1. Haftungsausschluss</h2>
          <p>
            DEHOGA Baden-Württemberg e.V. übernimmt keine Haftung für die
            Vollständigkeit, Richtigkeit oder Aktualität der auf dieser
            Internetseite zur Verfügung gestellten Informationen. Änderungen
            bleiben ausdrücklich jederzeit vorbehalten.
          </p>
          <p>
            Eine Haftung für Schäden, die aus der Nutzung von Inhalten bzw. der
            Erbringung von Dienstleistungen dieser Internetseite entstehen, wird
            ausgeschlossen, soweit nicht nachweislich grob fahrlässiges oder
            vorsätzliches Verhalten vorliegt.
          </p>
          <p>
            Alle Angebote auf dieser Internetseite sind freibleibend und
            unverbindlich.
          </p>
          <h2>2. Haftungsausschluss für Links</h2>
          <p>
            Diese Internetseite enthält Links zu externen Internetseiten
            Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb kann
            für diese fremden Inhalte auch keine Gewähr übernommen werden. Die
            Inhalte fremder Seiten, auf die hier verlinkt wird, spiegeln nicht
            die Meinung des Internetseitenbetreibers wider, sondern dienen
            lediglich der Information und der Darstellung von Zusammenhängen.
            Für die Inhalte der verlinkten Seiten ist stets der jeweilige
            Anbieter oder Betreiber der Seiten verantwortlich.
          </p>
          <p>
            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
            mögliche Rechtsverstöße überprüft. Zum Zeitpunkt der Verlinkung
            waren rechtswidrige Inhalte nicht erkennbar. Eine permanente
            inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
            Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.
          </p>
          <h2>3. Geschlechtsneutrale Formulierungen</h2>
          <p>
            Wenn auf diesen Seiten Formulierungen in männlicher Sprachform
            gewählt werden, so dient dies ausschließlich der besseren Lesbarkeit
            und stellt keine Diskriminierung dar. Sinngemäß ist
            selbstverständlich auch immer die weibliche oder diverse Sprachform
            gemeint.
          </p>
          <h2>4. Urheberrecht</h2>
          <p>
            Die auf dieser Internetseite eingestellten Inhalte und Werke
            (insbesondere Text und Bild), sowie die Gestaltung der Internetseite
            sind urheberrechtlich geschützt. Die Vervielfältigung, Bearbeitung,
            Verbreitung und jede Art der Verwertung ist nur nach vorheriger
            schriftlicher Zustimmung durch den DEHOGA Baden-Württemberg e.V.
            oder bei Werken Dritter durch diese gestattet. Hiervon ausgenommen
            sind nur Inhalte und Werke, die ausdrücklich als zum Download und
            zur weiteren Verwendung gekennzeichnet sind.
          </p>
          <h2>5. Markenrecht</h2>
          <p>
            DEHOGA Tourismus Baden-Württemberg ist eine eingetragene Marken. Die
            auf dieser Internetseite verwendeten Logos, Warenzeichen und Marken
            dürfen nicht ohne vorherige schriftliche Zustimmung des DEHOGA
            Baden-Württemberg e.V. verwendet werden.
          </p>
          <h2>6. Online-Streitbeilegung</h2>
          <p>
            Gemäß der Europäischen Verordnung über die Online-Streitbeilegung in
            Verbraucherangelegenheiten (ODR-Verordnung) weisen wir auf die
            Plattform zur Online-Streitbeilegung der Europäischen Kommission
            hin:{" "}
            <a href="http://ec.europa.eu/consumers/odr/">
              http://ec.europa.eu/consumers/odr/
            </a>
          </p>
          <h2>7. Teilnahme am Streitbeilegungsverfahren</h2>
          <p>
            DEHOGA Baden-Württemberg e.V., DEHOGA Beratung GmbH, DEHOGA
            Tourismus Baden-Württemberg GmbH und DEHOGA Akademie nehmen nicht an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teil.
          </p>
          <h2>8. Design und Programmierung</h2>
          <p>Moritz Frölich 72250 Freudenstadt</p>
          <h2>9. Inhaltliche Anpassung</h2>
          <p>Steffen Schillinger</p>
          <p>72250 Freudenstadt</p>
        </div>
    </div>
    </Layout>)
};

export default Imprint;
